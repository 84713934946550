import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import "../../styles/project.css"


const ProjectSection = ({ projectLink, projectName, projectTitle, projectDate, clientRollOrName, tech1, projectDescription }) => {

  return (
    <section id={`section${projectName}`}>
      <div className="project project--resume" id={projectName}>
        <div className="project_heading">
          <h2>
            {projectTitle}
          </h2>
          <h3 className="project_employment" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <small>{!!projectLink ? (
              <a className="projectLink" href={projectLink} target="_blank" rel="noopener">
                {clientRollOrName}
              </a>
            ) : (clientRollOrName)}</small>
            <small>{projectDate}</small>
          </h3>
        </div>
        <div className="project_details project_details--resume">
          <div className="project_descp">
            <p>
              {tech1}
              {!!projectDescription ? (
                <ul>
                  {projectDescription.map((item: string, i: any) => (
                    <li className='project_listItem' key={i}>
                      {item}
                    </li>
                  ))

                  }
                </ul>
              ) : ("")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
/*{imgSrc !== undefined && (
  <StaticImage src={imgSrc} alt={altText} layout="constrained" />
  )}*/